import { useState, useEffect } from "react";
import axios from "axios";


const useAxios = <T>(url: string, interval = 200) => {
    const [data, setData] = useState<T[]>([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        const intervalId = setInterval(() => {
            axios.get(url)
                .then(response => {
                    setData(response.data);
                    setError(null);
                })
                .catch(error => {
                    setError(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, interval);

        return () => clearInterval(intervalId);
    }, [url, interval]);

    return { data, isLoading, error };
};


export default useAxios;
