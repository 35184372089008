import { WHITE, RED, GREEN } from "../constants/colors";


export function testResultStyle(testResult: string) {
    let background;
    let color;

    if (testResult === "pass") {
        background = GREEN;
        color = WHITE;
    } else {
        background = RED;
        color = WHITE;
    }

    return { background, color };
}