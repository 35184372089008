import { ChartData, ChartOptions } from "chart.js";
import { ITest } from "../interfaces/IOutboundData";


export const chartOptions: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            beginAtZero: true,
        },
        x: {
            beginAtZero: true,
        },
    },
    animation: {
        duration: 0,
    },
};

export const chartData = (testResults: ITest[], selectedTestId: string): ChartData<"line"> => {
    const chartLabels: number[] = [];
    const chartData: number[] = [];

    for (let i = 0; i < testResults.length; i++) {
        if (testResults[i].test_id === selectedTestId) {
            const testResult = testResults[i].test_results;

            for (let j = 1; j < testResult.length; j++) {
                const currentTimestamp = new Date(testResult[j].updatedAt).getTime();
                const previousTimestamp = new Date(testResult[j - 1].updatedAt).getTime();
                const timeDifference = currentTimestamp - previousTimestamp;

                chartLabels.push(j);
                chartData.push(timeDifference);
            }
        }
    }

    return {
        labels: chartLabels,
        datasets: [
            {
                label: "Time Difference (ms)",
                data: chartData,
                backgroundColor: "#09445A",
                borderColor: "#09445A",
            },
        ],
    };
};