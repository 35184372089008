import React from "react";
import Switch from "react-switch";


interface Props {
    onChange: () => void;
    checked: boolean;
}


const ToggleSwitch: React.FC<Props> = ({ onChange, checked }) => {
    return (
        <Switch
            onChange={onChange}
            checked={!!checked}
            onColor={"#09445A"}
            uncheckedIcon={false}
            checkedIcon={false}
            offColor={"#ccc"}
        />
    );
};


export default ToggleSwitch;
