import React from "react";
import "chart.js/auto";
import { Line } from 'react-chartjs-2';
import { chartData, chartOptions } from "../config/ChartConfig";
import { ITest } from "../interfaces/IOutboundData";


interface Props {
    testResults: ITest[];
    selectedTestId: string;
}


const Chart: React.FC<Props> = ({ testResults, selectedTestId }) => {
    return (
        <div className="chart-wrapper">
            <Line data={chartData(testResults, selectedTestId)} options={chartOptions} />
        </div>
    );
};


export default Chart;