import { SelectedTime, ISerialNumbers, ITest } from "../interfaces/IOutboundData";


export function timeFilter(selectedTime: SelectedTime, serialNumbers: ISerialNumbers[], testResults: ITest[]) {
    if (selectedTime === SelectedTime.Last24Hours) {
        const last24Hours = new Date(Date.now() - 24 * 60 * 60 * 1000);
        serialNumbers = serialNumbers.filter(sr => {
            const updatedSerialNums = testResults.find(tr => tr.serial_num === sr.serial_num);
            return updatedSerialNums || new Date(sr.updatedAt) >= last24Hours;
        });
    } else if (selectedTime === SelectedTime.Last30Minutes) {
        const last30Minutes = new Date(Date.now() - 30 * 60 * 1000);
        serialNumbers = serialNumbers.filter(sr => {
            const updatedSerialNums = testResults.find(tr => tr.serial_num === sr.serial_num);
            return updatedSerialNums || new Date(sr.updatedAt) >= last30Minutes;
        });
    }

    return serialNumbers;
};

export function getTimeThreshold(selectedTime: SelectedTime) {
    const now = new Date().getTime();

    if (selectedTime === SelectedTime.Last24Hours) {
        return new Date(now - 24 * 60 * 60 * 1000);
    } else if (selectedTime === SelectedTime.Last30Minutes) {
        return new Date(now - 30 * 60 * 1000);
    }

    return new Date(0);
}