import React from "react";
import Table from "./components/Table";


const App: React.FC = () => {
    return (
        <Table />
    );
};


export default App;
