import React, { useEffect, useState } from "react";
import axios from "axios";
import useAxios from "../hooks/useAxios";
import ToggleSwitch from "./ToggleSwitch";
import { SERIAL_NUM_LIST_URL, TEST_STATUS_OUTBOUND_URL, TEST_STATUS_INBOUND_URL, TEST_RESULT_URL } from "../constants/envVars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Chart from "./Chart";
import { ISerialNumbers, ITest, SelectedTime } from "../interfaces/IOutboundData";
import { sortByUpdatedAt } from "../utils/checkDataUpdates";
import { getTimeThreshold, timeFilter } from "../utils/timeFilter";
import { testResultStyle } from "../utils/getCellStyles";


const Table: React.FC = () => {
    const [selectedSerialNum, setSelectedSerialNum] = useState("");
    const [testId, setTestId] = useState("");
    const [selectedTime, setSelectedTime] = useState(SelectedTime.All);
    const [active, setActive] = useState<{ [key: string]: boolean; }>({});
    const [openChart, setOpenChart] = useState<{ [key: string]: boolean; }>({});
    const { data: serialNumbers } = useAxios<ISerialNumbers>(SERIAL_NUM_LIST_URL);
    const { data: testResults } = useAxios<ITest>(`${TEST_RESULT_URL}/${selectedSerialNum || 0}`);
    const filteredSerialNumbers = timeFilter(selectedTime, serialNumbers, testResults);
    const sortedTestResults = sortByUpdatedAt(testResults);
    const filteredResults = sortedTestResults.filter(res => res.serial_num === selectedSerialNum || "");

    useEffect(() => {
        filteredResults.forEach(result => {
            const testId = result.test_id;

            axios.get(`${TEST_STATUS_OUTBOUND_URL}/${selectedSerialNum}/${testId}`)
                .then(res => {
                    let isActive = true;
                    if (res.data.length > 0) isActive = !!res.data[0].active;

                    setActive(prev => ({
                        ...prev,
                        [testId]: isActive
                    }));
                })
                .catch(error => console.log(error));
        });

        // eslint-disable-next-line
    }, [testResults]);

    const toggleTestStatus = (testId: string) => {
        axios
            .post(`${TEST_STATUS_INBOUND_URL}`, { test_id: testId, serial_num: selectedSerialNum, active: !active[testId] })
            .then(() =>
                setActive(prev => ({
                    ...prev,
                    [testId]: !active[testId]
                }))
            )
            .catch(error => console.log(error));
    };

    const handleOpenChart = (testId: string) => {
        const updatedOpenCharts: { [key: string]: boolean; } = {};

        Object.keys(openChart).forEach((chartTestId) => {
            updatedOpenCharts[chartTestId] = false;
        });

        updatedOpenCharts[testId] = !openChart[testId];

        setOpenChart(updatedOpenCharts);
        setTestId(testId);
    };

    const handleSelectedTime = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newSelectedTime = e.target.value as SelectedTime;
        setSelectedTime(newSelectedTime);

        const hasResults = testResults.some(result =>
            result.serial_num === selectedSerialNum &&
            (newSelectedTime === SelectedTime.All || new Date(result.updatedAt) >= getTimeThreshold(newSelectedTime))
        );

        if (!hasResults) setSelectedSerialNum("");
    };

    return (
        <div className="table-container">
            <select onChange={handleSelectedTime}>
                <option value={SelectedTime.All}>All Time</option>
                <option value={SelectedTime.Last24Hours}>Last 24 Hours</option>
                <option value={SelectedTime.Last30Minutes}>Last 30 Minutes</option>
            </select>

            <select onChange={(e) => setSelectedSerialNum(e.target.value)}>
                <option value="">Serial Numbers</option>
                {filteredSerialNumbers.map(serialNumber => (
                    <option key={serialNumber.serial_num} value={serialNumber.serial_num}>
                        {serialNumber.serial_num}
                    </option>
                ))}
            </select>

            <h1>Test Results</h1>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Text</th>
                        <th>Result</th>
                        <th>Open Chart</th>
                        <th>Execute Test</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredResults.map(result => (
                        <tr key={result.test_id}>
                            <td>{result.test_id}</td>
                            <td>{result.test_results[result.test_results.length - 1].text}</td>
                            <td style={testResultStyle(result.test_results[result.test_results.length - 1].result)}>
                                {result.test_results[result.test_results.length - 1].result}
                            </td>
                            <td>
                                <FontAwesomeIcon
                                    className="toggle-icon"
                                    onClick={() => handleOpenChart(result.test_id)}
                                    icon={openChart[result.test_id] ? faMinusCircle : faPlusCircle}
                                />
                            </td>
                            <td>
                                <ToggleSwitch onChange={() => toggleTestStatus(result.test_id)} checked={active[result.test_id]} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {openChart[testId] && selectedSerialNum && <Chart testResults={filteredResults} selectedTestId={testId} />}
        </div>
    );
};


export default Table;