export interface ISerialNumbers {
    createdAt: string;
    serial_num: string;
    updatedAt: string;
}

interface ITestResult {
    createdAt: Date;
    result: string;
    text: string;
    updatedAt: Date;
}

export interface ITest {
    createdAt: Date;
    serial_num: string;
    test_id: string;
    test_results: ITestResult[];
    updatedAt: Date;
}

export enum SelectedTime {
    All = "all",
    Last24Hours = "last24H",
    Last30Minutes = "last30M",
}